// DO NOT EDIT THIS FILE
// This file has been generated by gen-api-models
// eslint-disable sonar/max-union-size
// eslint-disable sonarjs/no-identical-functions

import * as t from "io-ts"; 


import * as r from "@pagopa/ts-commons/lib/requests";

import { ProblemJson } from "./ProblemJson";

import { ArchetypeList } from "./ArchetypeList";

import { Archetype } from "./Archetype";

import { ArchetypeHandlingResult } from "./ArchetypeHandlingResult";

import { MockResourceFromArchetype } from "./MockResourceFromArchetype";

import { MockResource } from "./MockResource";

import { AppInfo } from "./AppInfo";

import { MockResourceList } from "./MockResourceList";

import { MockResourceGeneralInfo } from "./MockResourceGeneralInfo";

import { MockRule } from "./MockRule";

import { ScriptMetadataList } from "./ScriptMetadataList";

/****************************************************************
 * deleteMockResource_1
 */

// Request type definition
export type DeleteMockResource_1T = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly subsystem: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, undefined, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteMockResource_1DefaultResponses = {
  200: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteMockResource_1ResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function deleteMockResource_1Decoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteMockResource_1ResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<404, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteMockResource_1DefaultResponses as unknown) as DeleteMockResource_1ResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteMockResource_1DefaultDecoder = () =>
  deleteMockResource_1Decoder();

/****************************************************************
 * getArchetypes
 */

// Request type definition
export type GetArchetypesT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit: number;
    readonly page: number;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ArchetypeList, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getArchetypesDefaultResponses = {
  200: ArchetypeList,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetArchetypesResponsesT<
  A0 = ArchetypeList,
  C0 = ArchetypeList,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  429: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function getArchetypesDecoder<
  A0 = ArchetypeList,
  C0 = ArchetypeList,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<GetArchetypesResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<429, A3, "X-Request-Id">
  | r.IResponseType<500, A4, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getArchetypesDefaultResponses as unknown) as GetArchetypesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A3, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A4, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getArchetypesDefaultDecoder = () => getArchetypesDecoder();

/****************************************************************
 * createArchetype
 */

// Request type definition
export type CreateArchetypeT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: Archetype;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, Archetype, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createArchetypeDefaultResponses = {
  201: Archetype,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateArchetypeResponsesT<
  A0 = Archetype,
  C0 = Archetype,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  409: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function createArchetypeDecoder<
  A0 = Archetype,
  C0 = Archetype,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateArchetypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<409, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createArchetypeDefaultResponses as unknown) as CreateArchetypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d201, d401), d403),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createArchetypeDefaultDecoder = () => createArchetypeDecoder();

/****************************************************************
 * importArchetypesFromOpenAPI
 */

// Request type definition
export type ImportArchetypesFromOpenAPIT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly subsystem: string;
    readonly file: File;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ArchetypeHandlingResult, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const importArchetypesFromOpenAPIDefaultResponses = {
  200: ArchetypeHandlingResult,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type ImportArchetypesFromOpenAPIResponsesT<
  A0 = ArchetypeHandlingResult,
  C0 = ArchetypeHandlingResult,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  409: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function importArchetypesFromOpenAPIDecoder<
  A0 = ArchetypeHandlingResult,
  C0 = ArchetypeHandlingResult,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        ImportArchetypesFromOpenAPIResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<409, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((importArchetypesFromOpenAPIDefaultResponses as unknown) as ImportArchetypesFromOpenAPIResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const importArchetypesFromOpenAPIDefaultDecoder = () =>
  importArchetypesFromOpenAPIDecoder();

/****************************************************************
 * getArchetype
 */

// Request type definition
export type GetArchetypeT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly archetypeId: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Archetype, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getArchetypeDefaultResponses = {
  200: Archetype,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetArchetypeResponsesT<
  A0 = Archetype,
  C0 = Archetype,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getArchetypeDecoder<
  A0 = Archetype,
  C0 = Archetype,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetArchetypeResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4, A5, C5>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<404, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getArchetypeDefaultResponses as unknown) as GetArchetypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getArchetypeDefaultDecoder = () => getArchetypeDecoder();

/****************************************************************
 * updateArchetype
 */

// Request type definition
export type UpdateArchetypeT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly archetypeId: string;
    readonly body: Archetype;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, Archetype, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateArchetypeDefaultResponses = {
  200: Archetype,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateArchetypeResponsesT<
  A0 = Archetype,
  C0 = Archetype,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function updateArchetypeDecoder<
  A0 = Archetype,
  C0 = Archetype,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateArchetypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<404, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateArchetypeDefaultResponses as unknown) as UpdateArchetypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateArchetypeDefaultDecoder = () => updateArchetypeDecoder();

/****************************************************************
 * createMockResourceFromArchetype
 */

// Request type definition
export type CreateMockResourceFromArchetypeT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly archetypeId: string;
    readonly body: MockResourceFromArchetype;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, MockResource, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createMockResourceFromArchetypeDefaultResponses = {
  201: MockResource,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateMockResourceFromArchetypeResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createMockResourceFromArchetypeDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateMockResourceFromArchetypeResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<404, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createMockResourceFromArchetypeDefaultResponses as unknown) as CreateMockResourceFromArchetypeResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d401),
            d403
          ),
          d404
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createMockResourceFromArchetypeDefaultDecoder = () =>
  createMockResourceFromArchetypeDecoder();

/****************************************************************
 * healthCheck
 */

// Request type definition
export type HealthCheckT = r.IGetApiRequestType<
  { readonly ApiKey: string; readonly Authorization: string },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, AppInfo, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const healthCheckDefaultResponses = {
  200: AppInfo,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type HealthCheckResponsesT<
  A0 = AppInfo,
  C0 = AppInfo,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  429: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function healthCheckDecoder<
  A0 = AppInfo,
  C0 = AppInfo,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<HealthCheckResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<429, A3, "X-Request-Id">
  | r.IResponseType<500, A4, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((healthCheckDefaultResponses as unknown) as HealthCheckResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A3, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A4, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const healthCheckDefaultDecoder = () => healthCheckDecoder();

/****************************************************************
 * getMockResources
 */

// Request type definition
export type GetMockResourcesT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly limit: number;
    readonly page: number;
    readonly name?: string;
    readonly tag?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, MockResourceList, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getMockResourcesDefaultResponses = {
  200: MockResourceList,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetMockResourcesResponsesT<
  A0 = MockResourceList,
  C0 = MockResourceList,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  429: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function getMockResourcesDecoder<
  A0 = MockResourceList,
  C0 = MockResourceList,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetMockResourcesResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<429, A3, "X-Request-Id">
  | r.IResponseType<500, A4, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getMockResourcesDefaultResponses as unknown) as GetMockResourcesResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A3, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A4, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getMockResourcesDefaultDecoder = () => getMockResourcesDecoder();

/****************************************************************
 * createMockResource
 */

// Request type definition
export type CreateMockResourceT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly body: MockResource;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, MockResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createMockResourceDefaultResponses = {
  201: MockResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateMockResourceResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createMockResourceDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateMockResourceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createMockResourceDefaultResponses as unknown) as CreateMockResourceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createMockResourceDefaultDecoder = () =>
  createMockResourceDecoder();

/****************************************************************
 * deleteMockResource
 */

// Request type definition
export type DeleteMockResourceT = r.IDeleteApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly resourceId: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<204, undefined, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const deleteMockResourceDefaultResponses = {
  204: t.undefined,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type DeleteMockResourceResponsesT<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  204: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function deleteMockResourceDecoder<
  A0 = undefined,
  C0 = undefined,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        DeleteMockResourceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<204, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<404, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((deleteMockResourceDefaultResponses as unknown) as DeleteMockResourceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 204: overrideTypes } : overrideTypes)
  };

  const d204 = (type[204].name === "undefined"
    ? r.constantResponseDecoder<undefined, 204, "X-Request-Id">(204, undefined)
    : r.ioResponseDecoder<
        204,
        typeof type[204]["_A"],
        typeof type[204]["_O"],
        "X-Request-Id"
      >(204, type[204])) as r.ResponseDecoder<
    r.IResponseType<204, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d204, d401), d403),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const deleteMockResourceDefaultDecoder = () =>
  deleteMockResourceDecoder();

/****************************************************************
 * getMockResource
 */

// Request type definition
export type GetMockResourceT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly resourceId: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, MockResource, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getMockResourceDefaultResponses = {
  200: MockResource,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type GetMockResourceResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  404: t.Type<A3, C3>;
  429: t.Type<A4, C4>;
  500: t.Type<A5, C5>;
};

export function getMockResourceDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = ProblemJson,
  C3 = ProblemJson,
  A4 = undefined,
  C4 = undefined,
  A5 = ProblemJson,
  C5 = ProblemJson
>(
  overrideTypes:
    | Partial<
        GetMockResourceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<404, A3, "X-Request-Id">
  | r.IResponseType<429, A4, "X-Request-Id">
  | r.IResponseType<500, A5, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getMockResourceDefaultResponses as unknown) as GetMockResourceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A3, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A4, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A5, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getMockResourceDefaultDecoder = () => getMockResourceDecoder();

/****************************************************************
 * updateMockResource
 */

// Request type definition
export type UpdateMockResourceT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly resourceId: string;
    readonly body: MockResource;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, MockResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateMockResourceDefaultResponses = {
  200: MockResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateMockResourceResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateMockResourceDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateMockResourceResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateMockResourceDefaultResponses as unknown) as UpdateMockResourceResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateMockResourceDefaultDecoder = () =>
  updateMockResourceDecoder();

/****************************************************************
 * updateMockResourceGeneralInfo
 */

// Request type definition
export type UpdateMockResourceGeneralInfoT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly resourceId: string;
    readonly body: MockResourceGeneralInfo;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, MockResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateMockResourceGeneralInfoDefaultResponses = {
  200: MockResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateMockResourceGeneralInfoResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateMockResourceGeneralInfoDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateMockResourceGeneralInfoResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateMockResourceGeneralInfoDefaultResponses as unknown) as UpdateMockResourceGeneralInfoResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateMockResourceGeneralInfoDefaultDecoder = () =>
  updateMockResourceGeneralInfoDecoder();

/****************************************************************
 * createMockRule
 */

// Request type definition
export type CreateMockRuleT = r.IPostApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly resourceId: string;
    readonly body: MockRule;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<201, MockResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<409, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const createMockRuleDefaultResponses = {
  201: MockResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  409: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type CreateMockRuleResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  201: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  409: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function createMockRuleDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        CreateMockRuleResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<201, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<409, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((createMockRuleDefaultResponses as unknown) as CreateMockRuleResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 201: overrideTypes } : overrideTypes)
  };

  const d201 = (type[201].name === "undefined"
    ? r.constantResponseDecoder<undefined, 201, "X-Request-Id">(201, undefined)
    : r.ioResponseDecoder<
        201,
        typeof type[201]["_A"],
        typeof type[201]["_O"],
        "X-Request-Id"
      >(201, type[201])) as r.ResponseDecoder<
    r.IResponseType<201, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d409 = (type[409].name === "undefined"
    ? r.constantResponseDecoder<undefined, 409, "X-Request-Id">(409, undefined)
    : r.ioResponseDecoder<
        409,
        typeof type[409]["_A"],
        typeof type[409]["_O"],
        "X-Request-Id"
      >(409, type[409])) as r.ResponseDecoder<
    r.IResponseType<409, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d201, d400),
            d401
          ),
          d403
        ),
        d409
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const createMockRuleDefaultDecoder = () => createMockRuleDecoder();

/****************************************************************
 * updateMockRule
 */

// Request type definition
export type UpdateMockRuleT = r.IPutApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly resourceId: string;
    readonly ruleId: string;
    readonly body: MockRule;
  },
  "Content-Type" | "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, MockResource, "X-Request-Id">
  | r.IResponseType<400, ProblemJson, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<404, ProblemJson, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const updateMockRuleDefaultResponses = {
  200: MockResource,
  400: ProblemJson,
  401: t.undefined,
  403: t.undefined,
  404: ProblemJson,
  429: t.undefined,
  500: ProblemJson
};

export type UpdateMockRuleResponsesT<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  400: t.Type<A1, C1>;
  401: t.Type<A2, C2>;
  403: t.Type<A3, C3>;
  404: t.Type<A4, C4>;
  429: t.Type<A5, C5>;
  500: t.Type<A6, C6>;
};

export function updateMockRuleDecoder<
  A0 = MockResource,
  C0 = MockResource,
  A1 = ProblemJson,
  C1 = ProblemJson,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson,
  A5 = undefined,
  C5 = undefined,
  A6 = ProblemJson,
  C6 = ProblemJson
>(
  overrideTypes:
    | Partial<
        UpdateMockRuleResponsesT<
          A0,
          C0,
          A1,
          C1,
          A2,
          C2,
          A3,
          C3,
          A4,
          C4,
          A5,
          C5,
          A6,
          C6
        >
      >
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<400, A1, "X-Request-Id">
  | r.IResponseType<401, A2, "X-Request-Id">
  | r.IResponseType<403, A3, "X-Request-Id">
  | r.IResponseType<404, A4, "X-Request-Id">
  | r.IResponseType<429, A5, "X-Request-Id">
  | r.IResponseType<500, A6, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((updateMockRuleDefaultResponses as unknown) as UpdateMockRuleResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4,
      A5,
      C5,
      A6,
      C6
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d400 = (type[400].name === "undefined"
    ? r.constantResponseDecoder<undefined, 400, "X-Request-Id">(400, undefined)
    : r.ioResponseDecoder<
        400,
        typeof type[400]["_A"],
        typeof type[400]["_O"],
        "X-Request-Id"
      >(400, type[400])) as r.ResponseDecoder<
    r.IResponseType<400, A1, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A2, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A3, "X-Request-Id">
  >;

  const d404 = (type[404].name === "undefined"
    ? r.constantResponseDecoder<undefined, 404, "X-Request-Id">(404, undefined)
    : r.ioResponseDecoder<
        404,
        typeof type[404]["_A"],
        typeof type[404]["_O"],
        "X-Request-Id"
      >(404, type[404])) as r.ResponseDecoder<
    r.IResponseType<404, A4, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A5, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A6, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(
        r.composeResponseDecoders(
          r.composeResponseDecoders(
            r.composeResponseDecoders(d200, d400),
            d401
          ),
          d403
        ),
        d404
      ),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const updateMockRuleDefaultDecoder = () => updateMockRuleDecoder();

/****************************************************************
 * getScripts
 */

// Request type definition
export type GetScriptsT = r.IGetApiRequestType<
  {
    readonly ApiKey: string;
    readonly Authorization: string;
    readonly name?: string;
  },
  "Ocp-Apim-Subscription-Key" | "Authorization",
  never,
  | r.IResponseType<200, ScriptMetadataList, "X-Request-Id">
  | r.IResponseType<401, undefined, "X-Request-Id">
  | r.IResponseType<403, undefined, "X-Request-Id">
  | r.IResponseType<429, undefined, "X-Request-Id">
  | r.IResponseType<500, ProblemJson, "X-Request-Id">
>;

export const getScriptsDefaultResponses = {
  200: ScriptMetadataList,
  401: t.undefined,
  403: t.undefined,
  429: t.undefined,
  500: ProblemJson
};

export type GetScriptsResponsesT<
  A0 = ScriptMetadataList,
  C0 = ScriptMetadataList,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
> = {
  200: t.Type<A0, C0>;
  401: t.Type<A1, C1>;
  403: t.Type<A2, C2>;
  429: t.Type<A3, C3>;
  500: t.Type<A4, C4>;
};

export function getScriptsDecoder<
  A0 = ScriptMetadataList,
  C0 = ScriptMetadataList,
  A1 = undefined,
  C1 = undefined,
  A2 = undefined,
  C2 = undefined,
  A3 = undefined,
  C3 = undefined,
  A4 = ProblemJson,
  C4 = ProblemJson
>(
  overrideTypes:
    | Partial<GetScriptsResponsesT<A0, C0, A1, C1, A2, C2, A3, C3, A4, C4>>
    | t.Type<A0, C0>
    | undefined = {}
): r.ResponseDecoder<
  | r.IResponseType<200, A0, "X-Request-Id">
  | r.IResponseType<401, A1, "X-Request-Id">
  | r.IResponseType<403, A2, "X-Request-Id">
  | r.IResponseType<429, A3, "X-Request-Id">
  | r.IResponseType<500, A4, "X-Request-Id">
> {
  const isDecoder = (d: any): d is t.Type<A0, C0> =>
    typeof d["_A"] !== "undefined";

  const type = {
    ...((getScriptsDefaultResponses as unknown) as GetScriptsResponsesT<
      A0,
      C0,
      A1,
      C1,
      A2,
      C2,
      A3,
      C3,
      A4,
      C4
    >),
    ...(isDecoder(overrideTypes) ? { 200: overrideTypes } : overrideTypes)
  };

  const d200 = (type[200].name === "undefined"
    ? r.constantResponseDecoder<undefined, 200, "X-Request-Id">(200, undefined)
    : r.ioResponseDecoder<
        200,
        typeof type[200]["_A"],
        typeof type[200]["_O"],
        "X-Request-Id"
      >(200, type[200])) as r.ResponseDecoder<
    r.IResponseType<200, A0, "X-Request-Id">
  >;

  const d401 = (type[401].name === "undefined"
    ? r.constantResponseDecoder<undefined, 401, "X-Request-Id">(401, undefined)
    : r.ioResponseDecoder<
        401,
        typeof type[401]["_A"],
        typeof type[401]["_O"],
        "X-Request-Id"
      >(401, type[401])) as r.ResponseDecoder<
    r.IResponseType<401, A1, "X-Request-Id">
  >;

  const d403 = (type[403].name === "undefined"
    ? r.constantResponseDecoder<undefined, 403, "X-Request-Id">(403, undefined)
    : r.ioResponseDecoder<
        403,
        typeof type[403]["_A"],
        typeof type[403]["_O"],
        "X-Request-Id"
      >(403, type[403])) as r.ResponseDecoder<
    r.IResponseType<403, A2, "X-Request-Id">
  >;

  const d429 = (type[429].name === "undefined"
    ? r.constantResponseDecoder<undefined, 429, "X-Request-Id">(429, undefined)
    : r.ioResponseDecoder<
        429,
        typeof type[429]["_A"],
        typeof type[429]["_O"],
        "X-Request-Id"
      >(429, type[429])) as r.ResponseDecoder<
    r.IResponseType<429, A3, "X-Request-Id">
  >;

  const d500 = (type[500].name === "undefined"
    ? r.constantResponseDecoder<undefined, 500, "X-Request-Id">(500, undefined)
    : r.ioResponseDecoder<
        500,
        typeof type[500]["_A"],
        typeof type[500]["_O"],
        "X-Request-Id"
      >(500, type[500])) as r.ResponseDecoder<
    r.IResponseType<500, A4, "X-Request-Id">
  >;

  return r.composeResponseDecoders(
    r.composeResponseDecoders(
      r.composeResponseDecoders(r.composeResponseDecoders(d200, d401), d403),
      d429
    ),
    d500
  );
}

// Decodes the success response with the type defined in the specs
export const getScriptsDefaultDecoder = () => getScriptsDecoder();
